import ImgMainMockup from '@assets/images/img_main_mockup.png';

import ImgMainBg from '@assets/images/img_main_bg.svg';
import ImgMainBg2 from '@assets/images/img_main_bg2.svg';
import MoFirstBg from '@assets/images/mo_first_bg.svg';
import IconTrip from '@assets/images/ic_trip_yellow.svg';
import IconFoot from '@assets/images/ic_footprint.svg';
import NavBar from './NavBar';

const MainPage = () => {
  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="w-full h-auto md:h-dvh relative">
      <div className="flex flex-col xl:flex-row w-full h-full justify-start xl:justify-center items-start xl:items-center">
        <div className="z-[100] xl:py-[100px] px-6 pt-10">
          <h1 className="text-[30px] lg:text-[48px] 2xl:text-[65px] font-g font-[500]">
            꿀같은 여행, <br />더 달콤하게 누리세요!
          </h1>
          <p className="text-[15px] lg:text-[22px] 2xl:text-[32px] tracking-[-0.64px] font-[400] mt-[10px]">
            신경쓰지 않아도 할인받는 꿀같은 여행앱
          </p>
          <div className="hidden mt-[100px] xl:flex flex-col items-start gap-4">
            <div
              className="flex gap-2 items-center bg-[#ffffff] py-4 px-8 rounded-full border border-[#131313] border-opacity-10 cursor-pointer"
              onClick={() => scrollToElement('kkultrip')}>
              <img src={IconTrip} alt="트립 아이콘" />
              <p className="font-sd font-[500] text-[18px] text-[#444]">
                트렌디한 여행할인 서비스
              </p>
            </div>
            <div
              className="flex gap-2 items-center bg-[#ffffff] py-4 px-8 rounded-full border border-[#131313] border-opacity-10 cursor-pointer"
              onClick={() => scrollToElement('footPrint')}>
              <img src={IconFoot} alt="탄소발자국 아이콘" />
              <p className="font-sd font-[500] text-[18px] text-[#444]">탄소발자국</p>
            </div>
          </div>
        </div>
        <div className="z-[100] w-full flex justify-center xl:block xl:w-auto overflow-hidden">
          <img className="max-w-[745px]" src={ImgMainMockup} alt="꿀트립 메인 이미지" />
        </div>
        <div className="xl:mt-[100px] flex flex-col items-start gap-4 z-[100] px-6 xl:hidden">
          <div
            className="flex gap-2 items-center bg-[#ffffff] py-2 xl:py-4 px-8 rounded-full border border-[#131313] border-opacity-10"
            onClick={() => scrollToElement('kkultrip')}>
            <img src={IconTrip} alt="트립 아이콘" />
            <p className="font-sd font-[500] text-[18px] text-[#444]">트렌디한 여행할인 서비스</p>
          </div>
          <div
            className="flex gap-2 items-center bg-[#ffffff] py-2 xl:py-4 px-8 rounded-full border border-[#131313] border-opacity-10"
            onClick={() => scrollToElement('footPrint')}>
            <img src={IconFoot} alt="탄소발자국 아이콘" />
            <p className="font-sd font-[500] text-[18px] text-[#444]">탄소발자국</p>
          </div>
        </div>
        <img
          className="hidden lg:block absolute top-0 w-full h-full z-[10]"
          src={ImgMainBg}
          alt="꿀트립 메인 배경 이미지"
        />
        <img
          className="hidden lg:block absolute top-0 w-full h-full "
          src={ImgMainBg2}
          alt="꿀트립 메인 배경 이미지"
        />
        <img
          className="block lg:hidden absolute top-0 w-full z-[10]"
          src={MoFirstBg}
          alt="꿀트립 메인 배경 이미지"
        />
      </div>
      <div className="hidden xl:block absolute top-0 right-0 m-8 z-[900]">
        <NavBar />
      </div>
      <div className="w-full flex justify-center xl:hidden py-16">
        <NavBar />
      </div>
    </section>
  );
};

export default MainPage;
