import React from 'react';
import '@assets/styles/style.css';
import {
  MainPage,
  SecondPage,
  ThirdPage,
  FourthPage,
  FifthPage,
  SixthPage,
  EighthPage,
  Footer,
} from '@components/template';

import {
  FootMainPage,
  FootSecondPage,
  FootMissionPage,
  FootLogPage,
  FootSocialPage,
} from '@components/footPrint';

function App() {
  return (
    <div className="w-full">
      <MainPage />
      <SecondPage />
      <ThirdPage />
      <FourthPage />
      <FifthPage />
      <SixthPage />
      <FootMainPage />
      <FootSecondPage />
      <FootMissionPage />
      <FootLogPage />
      <FootSocialPage />
      <EighthPage />
      <Footer />
    </div>
  );
}

export default App;
