import styled from 'styled-components';
import { ReactNode } from 'react';
import { BG_COLORS, BOX_SIZES, MOB_BOX_SIZES } from '@common/theme';
import { BG_COLOR, BOX_SIZE } from '@common/type';

interface STBoxProps {
  sx: any;
  size: BOX_SIZE;
  bgcolor: BG_COLOR;
}
const STBox = styled.div<STBoxProps>((props) => {
  return {
    ...BOX_SIZES[props.size],
    backgroundColor: BG_COLORS[props.bgcolor],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...props.sx,
    [`@media only screen and (${props.theme.sm})`]: {
      ...MOB_BOX_SIZES[props.size],
    },
  };
});

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  sx?: any;
  children?: ReactNode;
  bgcolor?: BG_COLOR;
  size?: BOX_SIZE;
}

const Box: React.FC<BoxProps> = (props): JSX.Element => {
  const { sx = {}, bgcolor = 'none', className, children, size = 'md', ...other } = props;

  return (
    <STBox sx={sx} bgcolor={bgcolor} size={size} className={[className || ''].join(' ')} {...other}>
      {children}
    </STBox>
  );
};

export default Box;
