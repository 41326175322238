import { Text } from '@components/atomic';

import icon from '@assets/images/ic_leaf_green_20dp.svg';
import log1 from '@assets/images/img_log_1.png';
import log2 from '@assets/images/img_log_2.png';
import log3 from '@assets/images/img_log_3.png';

import map1 from '@assets/images/img_map_1.png';
import map2 from '@assets/images/img_map_2.png';

export const FootLogPage = () => {
  return (
    <section className="w-full min-h-dvh bg-[#F2FFF4]">
      <div className="md:container md:mx-auto py-[80px] xl:py-[160px] px-6">
        <div className="flex flex-col items-start gap-8 mb-[80px] xl:mb-[150px]">
          <div className="p-3 rounded-full bg-[#D7F7A1]">
            <img className="w-10" src={icon} alt="탄소발자국 로그 아이콘" />
          </div>
          <Text size={'xl'} as={'h3'} sx={{ marginBottom: '16px' }} mobsx={{ marginBottom: '4px' }}>
            나의 탄소발자국을 <br />
            확인하세요!
          </Text>
        </div>
        <div className="flex flex-col xl:flex-row mb-40 xl:mb-20  xl:px-10 h-full">
          <div className="flex-[0.5] mb-12 xl:mb-0">
            <div className="w-full h-full flex flex-col justify-center gap-4">
              <h3 className="text-[#09B24C] text-[20px] xl:text-[24px] font-sd font-[600]">
                탄소발자국 로그
              </h3>
              <p className="font-sd text-[18px] xl:text-[20px] font-[500] leading-[140%] tracking-[-0.5px]">
                나의 탄소발생량, 절감량을 <br />
                한눈에 확인할 수 있습니다.
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-start gap-8 flex-[1.5] overflow-x-auto scrollbar-hide ">
            <img className="max-w-[264px]" src={log1} alt="탄소발자국 로그 화면" />
            <img className="max-w-[264px]" src={log2} alt="탄소발자국 로그 화면" />
            <img className="max-w-[264px]" src={log3} alt="탄소발자국 로그 화면" />
          </div>
        </div>
        <div className="flex flex-col xl:flex-row mb-20 xl:px-10 h-full">
          <div className="flex-[0.5] mb-12 xl:mb-0">
            <div className="w-full h-full flex flex-col justify-center gap-4 ">
              <h3 className="text-[#09B24C] text-[20px] xl:text-[24px] font-sd font-[600]">
                탄소발자국 지도
              </h3>
              <p className="font-sd text-[18px] xl:text-[20px] font-[500] leading-[140%] tracking-[-0.5px]">
                탄소 발생/절감 활동한 장소가 <br /> 지도에 표시됩니다.
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-start gap-8 flex-[1.5] overflow-x-auto scrollbar-hide ">
            <img className="max-w-[264px]" src={map1} alt="탄소발자국 로그 화면" />
            <img className="max-w-[264px]" src={map2} alt="탄소발자국 로그 화면" />
          </div>
        </div>
      </div>
    </section>
  );
};
