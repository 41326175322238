import { Text } from '@components/atomic';
import { Section, Wrap } from '@components/molecular';

import img_main from '@assets/images/img_sixth.png';
import img_main_mob from '@assets/images/img_sixth_mob.png';

const SixthPage = () => {
  return (
    <Section
      direction={'row'}
      bgcolor={'lightGrey'}
      mobsx={{ flexDirection: 'column-reverse', position: 'relative', paddingTop: '32px' }}
      mobheight="auto">
      <Wrap sx={{ flex: '1' }} align={'flex-end'} mobsx={{ flex: '3' }}>
        <img src={img_main} alt="꿀트립 여섯번째 이미지" className="img-sixth" />
        <img src={img_main_mob} alt="꿀트립 여섯번째 이미지" className="img-sixth-mob" />
      </Wrap>
      <Wrap sx={{ flex: '1' }} mobsx={{ padding: '32px 16px 32px 16px' }}>
        <div>
          <Text size={'xl'} as={'h3'} sx={{ marginBottom: '16px' }} mobsx={{ marginBottom: '4px' }}>
            가장 제주다운 하이퍼로컬 관광지,
            <br /> 날씨 정보를 보여드려요!
          </Text>
          <Text size={'md'} className="font-pre">
            제주 지역별로 고유의 로컬 힙 문화를 간직한 관광지와 <br className="br-mob" />
            실시간·예보 날씨를 확인하세요.
          </Text>
        </div>
      </Wrap>
    </Section>
  );
};

export default SixthPage;
