import React from 'react';
import { Modal } from '../molecular';

import ImgLoGo from '@assets/images/logo_kkul.svg';
import ImgQr from '@assets/images/qr_google.png';
import IcDelete from '@assets/images/ic_delete.svg';
import IcGoogle from '@assets/images/ic_android.svg';

interface ModalProps {
  onClose: () => void;
}

const ModalPage: React.FC<ModalProps> = ({ onClose }) => {
  return (
    <Modal onClick={() => onClose()}>
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <div className="delete-box">
          <img
            onClick={() => onClose()}
            src={IcDelete}
            alt="꿀트립 메인 이미지"
            className="ic-delete"
          />
        </div>
        <img src={ImgLoGo} alt="꿀트립 메인 이미지" className="img-logo" />
        <img src={ImgQr} alt="꿀트립 메인 이미지" className="img-qr" />
        <div className="ic-name">
          <img src={IcGoogle} alt="꿀트립 메인 이미지" className="ic-delete" />
          Google Play
        </div>
        <p>꿀트립 플레이스토어 이동하기</p>
      </div>
    </Modal>
  );
};

export default ModalPage;
