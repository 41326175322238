import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import { Text, Button } from '@components/atomic';
import { Section, Wrap } from '@components/molecular';
import { LinkInfo } from '@common/constants/ConstantInfo';

import ic_youtube from '@assets/images/ic_youtube.svg';


import { ModalApplePage, ModalGooglePage } from '@components/template';

const EighthPage = () => {
  const [isModalAppleOpen, SetIsModalAppleOpen] = useState(false);
  const [isModalGoogleOpen, SetIsModalGoogleOpen] = useState(false);

  // 웹 모바일 구분에 따른 이벤트 관리
  const handleModal = (el: string) => {
    if (isMobile) {
      if (el === 'Google Play') {
        return (window.location.href =
          'https://play.google.com/store/apps/details?id=com.kkultrip');
      } else {
        return (window.location.href =
          'itms-apps://itunes.apple.com/app/apple-store/id6443998458?mt=8');
      }
    } else {
      if (el === 'Google Play') {
        SetIsModalGoogleOpen(!isModalGoogleOpen);
        return;
      } else {
        SetIsModalAppleOpen(!isModalAppleOpen);
        return;
      }
    }
  };


  return (
    <Section
      direction="column"
      height={'400px'}
      bgcolor={'light'}
      mobheight={'auto'}
      mobsx={{ padding: '32px' }}>
      <Wrap sx={{ flex: '1' }} align={'center'} justify={'center'}>
        <Text size={'cus'} as={'h3'}>
          꿀트립으로 순수한 여행을 즐기세요!
        </Text>
      </Wrap>
      <Wrap
        direction={'row'}
        sx={{ flex: '0.5', gap: '16px' }}
        align={'flex-start'}

        justify={'center'}
        mobsx={{ flexDirection: 'column', alignItems: 'center', marginTop: '40px' }}>
        {LinkInfo.appLink.map((el, idx: number) => {
          return (
            <Button key={idx} className="app-button" onClick={() => handleModal(el.name)}>

              <img src={el.img} alt={`꿀트립 ${el.name}`} />
              {el.name}
            </Button>
          );
        })}
      </Wrap>

      <Wrap
        direction={'row'}
        sx={{ flex: '1' }}
        align={'flex-start'}
        justify={'center'}
        mobsx={{ marginBottom: '30px', marginTop: '16px' }}>

        <Button
          size={'sm'}
          font={'g'}
          href={'https://youtube.com/playlist?list=PLigwiU6vbLHtgQkNTYIDhOZpZD7cm8ZP5'}>
          <img src={ic_youtube} alt="꿀트립 유튜브" />
          꿀트립 가이드
        </Button>
      </Wrap>
      <Wrap
        direction={'row'}
        sx={{ flex: '1.2', gap: '12px' }}
        align={'flex-start'}
        justify={'center'}>
        {LinkInfo.social.map((el, idx: number) => {
          return (
            <Button key={idx} btnsize={'sm'} href={el.url}>
              <img src={el.img} alt={`꿀트립 ${el.name}`} />
            </Button>
          );
        })}
      </Wrap>
      {isModalAppleOpen && (
        <ModalApplePage onClose={() => SetIsModalAppleOpen(!isModalAppleOpen)} />
      )}
      {isModalGoogleOpen && (
        <ModalGooglePage onClose={() => SetIsModalGoogleOpen(!isModalGoogleOpen)} />
      )}
    </Section>
  );
};

export default EighthPage;
