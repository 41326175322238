import Img_Second from '@assets/images/img_second.svg';

const SecondPage = () => {
  return (
    <section className="relative w-full h-dvh  flex flex-row justify-center xl:gap-20 items-center py-[80px]  xl:py-[160px] px-6 z-[800]">
      <div className="flex flex-col gap-12 xl:gap-8 items-start w-full xl:w-auto">
        <div className="w-full h-[130px] p-[20px] bg-[#F7F7F7] rounded-[20px] flex items-center justify-center text-center">
          <p className="font-pre text-[16px] xl:text-[26px] font-[500] leading-[145%] tracking-[-0.78px]">
            더 저렴한 할인 쿠폰을 찾느라 피곤해요.
            <br /> 신경쓰지 않아도 알아서 할인이 되었으면 좋겠어요.
          </p>
        </div>
        <div className="w-full h-[130px] p-[20px] bg-[#F7F7F7] rounded-[20px] flex items-center justify-center text-center">
          <p className="font-pre text-[16px] xl:text-[26px] font-[500] leading-[145%] tracking-[-0.78px] ">
            날마다 할인율이 달라져서 여행계획을 <br /> 제대로 세울 수 없어요
          </p>
        </div>
        <div className="w-full h-[130px] p-[20px] bg-[#F7F7F7] rounded-[20px] flex items-center justify-center text-center">
          <p className="font-pre text-[16px] xl:text-[26px] font-[500] leading-[145%] tracking-[-0.78px]">
            친환경 여행을 하고 싶은데, <br />
            어떻게 해야할지 잘 모르겠어요
          </p>
        </div>
      </div>
      <div>
        <img src={Img_Second} alt="꿀트립 두번째 이미지" className="hidden w-[400px] xl:block" />
      </div>
    </section>
  );
};

export default SecondPage;
