import React from 'react';
import styled from 'styled-components';
import { FONT_COLORS, FONT_THEME, MO_FONT_THEME } from '@common/theme';
import { FONT_FAMILY, SIZE, COLORS } from '@common/type';

interface STTextProps {
  size: SIZE;
  color: COLORS;
  sx: any;
  mobsx: any;
}

const STText = styled.div<STTextProps>((props) => {
  return {
    ...FONT_THEME[props.size],
    color: FONT_COLORS[props.color],
    ...props.sx,
    [`@media only screen and (${props.theme.sm})`]: {
      ...MO_FONT_THEME[props.size],
      ...props.mobsx,
    },
  };
});

interface TextProps extends React.HTMLAttributes<HTMLDivElement> {
  as?: string;
  sx?: any;
  mobsx?: any;
  props?: any;
  font?: FONT_FAMILY;
  color?: COLORS;
  size?: SIZE;
}

/**
 * 꿀트립 랜딩페이지 전체 텍스트 관리
 * @param props
 * @returns
 */
const Text: React.FC<TextProps> = (props): JSX.Element => {
  const {
    as = 'div',
    size = 'md',
    font = 'g',
    color = 'primary',
    sx = {},
    mobsx = {},
    className,
    children,
    ...others
  } = props;

  return (
    <STText
      as={as}
      size={size}
      color={color}
      sx={sx}
      mobsx={mobsx}
      className={[`font-${font}`, className || ''].join(' ')}
      {...others}>
      {children}
    </STText>
  );
};

export default Text;
