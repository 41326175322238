import styled from 'styled-components';
import { ReactNode } from 'react';
import { BG_COLORS } from '@common/theme';
import { BG_COLOR } from '@common/type';

interface STWrapProps {
  sx: any;
  bgcolor: BG_COLOR;
  align: string;
  justify: string;
  direction: string;
  mobsx: any;
}
const STWrap = styled.div<STWrapProps>((props) => {
  return {
    backgroundColor: BG_COLORS[props.bgcolor],
    display: 'flex',
    flexDirection: props.direction,
    justifyContent: props.justify,
    alignItems: props.align,
    ...props.sx,
    [`@media only screen and (${props.theme.lg})`]: {
      ...props.mobsx,
    },
  };
});

interface WrapProps extends React.HTMLAttributes<HTMLDivElement> {
  sx?: any;
  mobsx?: any;
  children?: ReactNode;
  bgcolor?: BG_COLOR;
  align?: string;
  justify?: string;
  direction?: string;
}

export const Wrap: React.FC<WrapProps> = (props): JSX.Element => {
  const {
    sx = {},
    mobsx = {},
    bgcolor = 'none',
    justify = 'center',
    align = 'flex-start',
    direction = 'column',
    className,
    children,
    ...other
  } = props;

  return (
    <STWrap
      sx={sx}
      mobsx={mobsx}
      bgcolor={bgcolor}
      direction={direction}
      align={align}
      justify={justify}
      className={[className || ''].join(' ')}
      {...other}>
      {children}
    </STWrap>
  );
};

// export default Wrap;
