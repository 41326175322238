import React from 'react';
import { Modal } from '../molecular';

import ImgLoGo from '@assets/images/logo_kkul.svg';
import ImgQr from '@assets/images/qr_apple.png';
import IcDelete from '@assets/images/ic_delete.svg';
import IcApple from '@assets/images/ic_apple.svg';

interface ModalProps {
  onClose: () => void;
}

const ModalPage: React.FC<ModalProps> = ({ onClose }) => {
  return (
    <Modal className="modal" onClick={() => onClose()}>
      <div className="modal-box" onClick={(e) => e.stopPropagation()}>
        <div className="delete-box">
          <img
            onClick={() => onClose()}
            src={IcDelete}
            alt="꿀트립 메인 이미지"
            className="ic-delete"
          />
        </div>
        <img src={ImgLoGo} alt="꿀트립 메인 이미지" className="img-logo" />
        <img src={ImgQr} alt="꿀트립 메인 이미지" className="img-qr" />
        <div className="ic-name">
          <img src={IcApple} alt="꿀트립 메인 이미지" className="ic-delete" />
          App Store
        </div>
        <p>꿀트립 앱스토어 이동하기</p>
      </div>
    </Modal>
  );
};

export default ModalPage;
