export const FONT_THEME = {
  xxs: {
    fontSize: '14px',
    lineHeight: '22.27px',
  },
  xs: {
    fontSize: '15px',
    lineHeight: '22px',
  },
  sm: {
    fontSize: '18px',
    lineHeight: '15.84px',
  },
  md: {
    fontSize: '20px',
    lineHeight: '30px',
  },
  lg: {
    fontSize: '26px',
    lineHeight: '28px',
  },
  xl: {
    fontSize: '42px',
    lineHeight: '52px',
  },
  xxl: {
    fontSize: '65px',
    lineHeight: '75px',
  },
  cus: {
    fontSize: '42px',
    lineHeight: '52px',
  },
};

export const MO_FONT_THEME = {
  xxs: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  xs: {
    fontSize: '12px',
    lineHeight: '22px',
  },
  sm: {
    fontSize: '18px',
    lineHeight: '15.84px',
  },
  md: {
    fontSize: '14px',
    lineHeight: '16.25px',
  },
  lg: {
    fontSize: '14px',
    lineHeight: '18px',
  },
  xl: {
    fontSize: '22px',
    lineHeight: '30px',
  },
  xxl: {
    fontSize: '30px',
    lineHeight: '35px',
  },
  cus: {
    fontSize: '20px',
    lineHeight: '30px',
  },
};

export enum FONT_COLORS {
  primary = '#131313',
  secondary = '#555555',
  light = '#3C3C3C',
  white = '#FFFFFF',
}

export const BOX_SIZES = {
  sm: {
    width: '80px',
    height: '80px',
    padding: '16px',
    borderRadius: '12px',
    boxSizing: 'border-box',
  },
  md: {
    maxWidth: '200px',
    height: '56px',
    padding: '10px',
    borderRadius: '40px',
    boxSizing: 'border-box',
  },
  lg: {
    width: '600px',
    maxWidth: '600px',
    height: '130px',
    padding: '10px',
    borderRadius: '40px',
    boxSizing: 'border-box',
  },
};

export const MOB_BOX_SIZES = {
  sm: {
    width: '67px',
    height: '67px',
    borderRadius: '10px',
    boxSizing: 'border-box',
  },
  md: {
    width: 'auto',
    height: '56px',
    padding: '10px',
    borderRadius: '40px',
    boxSizing: 'border-box',
  },
  lg: {
    width: 'auto;',
    height: 'auto',
    padding: '16px',
    borderRadius: '24px',
    boxSizing: 'border-box',
  },
};

export enum BG_COLORS {
  yellow = 'rgba(252, 219, 86, 0.40)',
  lightYellow = '#FFE167',
  light = '#FAFAFA',
  lightGrey = '#E7EAED;',
  grey = '#F7F7F7',
  white = '#FFFFFF',
  none = 'transparent',
}

export const BUTTON_SIZES = {
  sm: {
    width: 'auto',
    height: 'auto',
  },
  md: {
    width: '172px',
    height: '36px',
  },
  lg: {
    width: '220px',
    height: '56px',
  },
};

const BREAK_POINT_SIZE = {
  xs: '640px',
  sm: '768px',
  md: '1024px',
  lg: '1280px',
  xl: '1536px',
  xxl: '1925px',
};

export const BREAK_POINT = {
  xs: `(max-width: ${BREAK_POINT_SIZE.xs})`,
  sm: `(max-width: ${BREAK_POINT_SIZE.sm})`,
  md: `(max-width: ${BREAK_POINT_SIZE.md})`,
  lg: `(max-width: ${BREAK_POINT_SIZE.lg})`,
  xl: `(max-width: ${BREAK_POINT_SIZE.xl})`,
  xxl: `(max-width: ${BREAK_POINT_SIZE.xxl})`,
};

export const GRID_THEME = {
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  gridTemplateRow: 'repeat(2, 1fr)',
  gap: '24px 32px',
};
