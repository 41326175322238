import styled from 'styled-components';
import { ReactNode } from 'react';
import { BG_COLORS } from '@common/theme';
import { BG_COLOR } from '@common/type';

interface STSectionProps {
  sx: any;
  mobsx: any;
  height: string;
  width: string;
  mobheight: string;
  direction: string;
  bgcolor: BG_COLOR;
}
const STSection = styled.section<STSectionProps>((props) => {
  return {
    backgroundColor: BG_COLORS[props.bgcolor],
    display: 'flex',
    width: '100vw',
    overflowX: 'hidden',

    flexDirection: props.direction,
    justifyContent: 'center',
    height: props.height,
    ...props.sx,
    [`@media only screen and (${props.theme.lg})`]: {
      flexDirection: 'column',
      height: props.mobheight,
      // padding: '32px 16px 32px 16px',
      ...props.mobsx,
    },
  };
});

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  sx?: any;
  mobsx?: any;
  width?: string;
  children?: ReactNode;
  bgcolor?: BG_COLOR;
  height?: string;
  mobheight?: string;
  direction?: string;
  as?: string;
}

export const Section: React.FC<SectionProps> = (props): JSX.Element => {
  const {
    as = 'section',
    sx = {},
    mobsx = {},
    width = '100%',
    bgcolor = 'white',
    height = '100vh',
    mobheight = '100vh',
    direction = 'row',
    className,
    children,
    ...other
  } = props;

  return (
    <STSection
      as={as}
      sx={sx}
      width={width}
      mobsx={mobsx}
      height={height}
      mobheight={mobheight}
      direction={direction}
      bgcolor={bgcolor}
      className={[className || ''].join(' ')}
      {...other}>
      {children}
    </STSection>
  );
};

// export default Section;
