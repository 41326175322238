import { Text } from '@components/atomic';

import img1 from '@assets/images/img_social_upload_1.png';
import img2 from '@assets/images/img_social_upload_2.png';
import img3 from '@assets/images/img_social_upload_3.png';
import img4 from '@assets/images/img_social_upload_4.png';
import center from '@assets/images/img_social_app.png';

export const FootSocialPage = () => {
  return (
    <section className="w-full">
      <div className="md:container md:mx-auto py-[160px] ">
        <div className="w-full flex flex-row gap-8  justify-center items-center mb-8 overflow-x-hidden">
          <img className="w-[240px]" src={img1} alt="소셜 이미지" />
          <img className="w-[240px]" src={img2} alt="소셜 이미지" />
          <img className="w-[456px]" src={center} alt="소셜 이미지" />
          <img className="w-[240px]" src={img3} alt="소셜 이미지" />
          <img className="w-[240px]" src={img4} alt="소셜 이미지" />
        </div>
        <div className="text-center">
          <Text size={'xl'} as={'h3'} sx={{ marginBottom: '16px' }} mobsx={{ marginBottom: '4px' }}>
            나의 친환경을 자랑하고,
            <br /> 평가해보세요.
          </Text>
        </div>
      </div>
    </section>
  );
};
