import React from 'react';
import styled from 'styled-components';
import { FONT_COLORS, FONT_THEME } from '@common/theme';
import { FONT_FAMILY, SIZE, COLORS } from '@common/type';

interface STAnchorProps {
  size: SIZE;
  color: COLORS;
  sx: any;
}

const STAnchor = styled.a<STAnchorProps>((props) => {
  return {
    ...FONT_THEME[props.size],
    color: FONT_COLORS[props.color],
    ...props.sx,
  };
});

interface AnchorProps extends React.HTMLAttributes<HTMLAnchorElement> {
  as?: string;
  sx?: any;
  font?: FONT_FAMILY;
  color?: COLORS;
  size?: SIZE;
  href?: string;
  target?: string;
}

/**
 * 꿀트립 랜딩페이지 전체 Anchor 관리
 * @param props
 * @returns
 */
const Anchor: React.FC<AnchorProps> = (props): JSX.Element => {
  const {
    size = 'xxs',
    font = 'noto',
    color = 'white',
    href = '#',
    target = '_blank',
    sx = {},

    className,
    children,
    ...others
  } = props;

  return (
    <STAnchor
      size={size}
      color={color}
      sx={sx}
      href={href}
      target={target}
      className={[`font-${font}`, className || ''].join(' ')}
      {...others}>
      {children}
    </STAnchor>
  );
};

export default Anchor;
