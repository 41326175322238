import { ReactNode } from 'react';
import styled from 'styled-components';
import { FONT_COLORS, FONT_THEME, BUTTON_SIZES } from '@common/theme';
import { FONT_FAMILY, SIZE, COLORS, BUTTON_SIZE } from '@common/type';

interface STButtonProps {
  size: SIZE;
  color: COLORS;
  sx: any;
  btnsize: BUTTON_SIZE;
}

const STButton = styled.button<STButtonProps>((props) => {
  return {
    ...BUTTON_SIZES[props.btnsize],
    ...FONT_THEME[props.size],
    color: FONT_COLORS[props.color],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    ...props.sx,
  };
});

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: SIZE;
  btnsize?: BUTTON_SIZE;
  color?: COLORS;
  font?: FONT_FAMILY;
  icon?: string;
  sx?: any;
  href?: string;
  target?: '_blank';
  children?: ReactNode;
}

const Button: React.FC<ButtonProps> = (props): JSX.Element => {
  const {
    children,
    icon,
    size = 'md',
    font = 'noto',
    btnsize = 'lg',
    color = 'primary',
    href,
    target = '_blank',
    sx = {},
    onClick,
    className,
    ...other
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (href) {
      window.open(href, target || '_blank');
    }
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <STButton
      size={size}
      btnsize={btnsize}
      color={color}
      sx={sx}
      className={[`font-${font}`, className || ''].join(' ')}
      {...other}
      onClick={handleClick}>
      {children}
    </STButton>
  );
};

export default Button;
