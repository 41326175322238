import React from 'react';
import styled from 'styled-components';
import { FooterInfo } from '@common/constants/ConstantInfo';
import Text from '@components/atomic/Text';
import Anchor from '@components/atomic/Anchor';

const STFooter = styled.footer`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 51px 260px 81px 260px;
  justify-content: space-between;
  align-items: center;
  background-color: #555;
  color: #fff;
  transition: all 0.5 ease-in-out;

  @media screen and (max-width: 1440px) {
    padding: 51px 100px 81px 100px;
  }
  @media screen and (max-width: 1024px) {
    padding: 51px 16px 81px 16px;
  }
  @media screen and (max-width: 815px) {
    padding: 20px 16px 60px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
  }
`;

const STBox = styled.div`
  flex: 1;
  text-align: left;
`;

const STRightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Footer = () => {
  const { title, buisnessInfo, address_1, address_2 } = FooterInfo.company;

  const kakaoText = FooterInfo.customer.kakao;
  const kakaoUrl = FooterInfo.customer.kakaoUrl;

  const kakaoJsx = (
    <>
      {kakaoText.split('{{')[0]}{' '}
      <a href={kakaoUrl} target="_blank" rel="noreferrer">
        {kakaoText.split('{{')[1].split('}}')[0]}
      </a>{' '}
      {kakaoText.split('}}')[1]}
    </>
  );

  const homePageJsx = (
    <>
      {buisnessInfo.split('{{')[0]}{' '}
      <a href={'https://ymjlab.com'} target="_blank" rel="noreferrer">
        {buisnessInfo.split('{{')[1].split('}}')[0]}
      </a>{' '}
      {buisnessInfo.split('}}')[1]}{' '}
    </>
  );

  let now: Date = new Date(); // 현재 날짜 및 시간
  let year: number = now.getFullYear(); // 연도

  return (
    <STFooter>
      <STBox>
        <div>
          <Text
            color="white"
            size="xxs"
            as="h2"
            font="noto"
            sx={{
              marginBottom: '8px',
            }}
            mobsx={{
              fontWeight: '500',
            }}>
            {FooterInfo.customer.title}
          </Text>
          <Text
            color="white"
            size="xxs"
            as="h2"
            font="noto"
            mobsx={{
              fontWeight: '500',
            }}>
            {kakaoJsx}
          </Text>
          <Text
            color="white"
            size="xxs"
            as="h2"
            font="noto"
            sx={{ marginBottom: '40px' }}
            mobsx={{
              fontWeight: '500',
            }}>
            {FooterInfo.customer.email}
          </Text>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {FooterInfo.link.map((el, idx: number) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }} key={idx}>
                <Anchor href={el.url} target="_blank" rel="noreferrer">
                  {el.title}
                </Anchor>
                {idx < FooterInfo.link.length - 1 && (
                  <div
                    style={{
                      marginLeft: '8px',
                      marginRight: '8px',
                      height: '14px',
                      border: '1px solid white',
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </STBox>
      <STRightBox>
        <div>
          <Text
            color="white"
            size="xxs"
            as="h4"
            font="noto"
            sx={{
              marginBottom: '8px',
            }}>
            {title}
          </Text>
          <Text color="white" size="xxs" font="noto">
            {homePageJsx}
          </Text>
          <Text color="white" size="xxs" font="noto">
            {address_1}
          </Text>
          <Text
            color="white"
            size="xxs"
            font="noto"
            sx={{
              marginBottom: '16px',
            }}>
            {address_2}
          </Text>
          <Text color="white" size="xxs" font="noto">
            Copyright &copy; YMJLAB Inc. {year}
          </Text>
        </div>
      </STRightBox>
    </STFooter>
  );
};

export default Footer;
