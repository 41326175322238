import IconFoot from '@assets/images/ic_footprint.svg';
import { Text } from '@components/atomic';
import footIcon from '@assets/images/img_compare_footprint.svg';

export const FootMainPage = () => {
  return (
    <section className="w-full h-auto xl:h-dvh bg-footPrint bg-[#EAFBFF]" id="footPrint">
      <div className="w-full h-full flex flex-col items-center py-[56px] xl:py-[120px]">
        <div className="flex gap-2 items-center bg-[#FFF] py-4 px-8 rounded-full mb-[56px] border border-[#131313] border-opacity-10">
          <img src={IconFoot} alt="탄소발자국 아이콘" />
          <p className="font-sd font-[500] text-[18px] text-[#444]">탄소발자국</p>
        </div>
        <div className="text-center mb-[48px]">
          <Text
            size={'xl'}
            as={'h3'}
            sx={{ marginBottom: '16px' }}
            mobsx={{ marginBottom: '16px' }}>
            탄소발자국 이란?
          </Text>
          <Text size={'md'} className="font-pre text-center">
            탄소발자국은 제품 생산부터 폐기까지 온실가스 배출량을 나타내며,
            <br className="br-mob" /> 지구온난화에 미치는 영향을 평가하는 지표입니다.
          </Text>
        </div>
        <div>
          <img className="w-[375px] xl:w-[645px]" src={footIcon} alt="관광분야 탄소발자국 설명" />
        </div>
      </div>
    </section>
  );
};
