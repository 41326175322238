import { footerInfo, IconList, LinkList } from '@common/interfaces/constants';

import IcSpoon from '@assets/images/ic_spoon24.svg';
import IcCup from '@assets/images/ic_cup24.svg';
import IcActivity from '@assets/images/ic_activity24.svg';
import IcTicket from '@assets/images/ic_ticket24.svg';
import IcCar from '@assets/images/ic_car24.svg';
import IcBuilding from '@assets/images/ic_building24.svg';
import IcShopping from '@assets/images/ic_shopping24.svg';
import IcEtc from '@assets/images/ic_etc24.svg';

import ic_android from '@assets/images/ic_android.svg';
import ic_apple from '@assets/images/ic_apple.svg';

import ic_kakao from '@assets/images/ic_kakao.svg';
import ic_insta from '@assets/images/ic_insta.svg';
import ic_youtube_circle from '@assets/images/ic_youtube_circle.svg';
import ic_blog from '@assets/images/bt_gray_blog_50dp.svg';

import ic_tumbler from '@assets/images/img_badge_tumbler.svg';
import ic_airport from '@assets/images/img_badge_airport.svg';
import ic_walking from '@assets/images/img_badge_bicycle.svg';
import ic_ship from '@assets/images/img_badge_ship.svg';

export const FooterInfo: footerInfo = {
  customer: {
    title: '고객센터',
    kakao: '카카오톡 {{kkultrip@kakao}} (평일 09시~17시)',
    kakaoUrl: 'https://pf.kakao.com/_alIxdb',
    email: '이메일문의 support@kkultrip.com',
  },
  link: [
    { title: '서비스 이용약관 ', url: 'https://kkultrip.com/Terms_of_Service.html' },
    { title: '개인정보 처리방침 ', url: 'https://kkultrip.com/privacy_policy.html' },
    { title: '제휴점 가입신청', url: 'https://shop-v2.kkultrip.com/' },
  ],

  company: {
    title: '(주)요망진연구소',
    buisnessInfo: '사업자번호 183-81-02296 ㅣ 대표 최성기 ㅣ {{ 회사소개 }}',
    address_1: '[본사] 제주특별자치도 제주시 동문로9길 13-1 (일도일동), W360',
    address_2: '[서울사무소] 서울특별시 마포구 마포대로 78 제2서울핀테크랩 11층',
  },
};

export const IconInfo: IconList = {
  icon: [
    {
      name: '식당',
      img: IcSpoon,
    },
    {
      name: '카페',
      img: IcCup,
    },
    {
      name: '엑티비티',
      img: IcActivity,
    },
    {
      name: '티켓',
      img: IcTicket,
    },
    {
      name: '모빌리티',
      img: IcCar,
    },
    {
      name: '숙박',
      img: IcBuilding,
    },
    {
      name: '쇼핑',
      img: IcShopping,
    },
    {
      name: '기타',
      img: IcEtc,
    },
  ],
};

export const LinkInfo: LinkList = {
  appLink: [
    {
      name: 'Google Play',
      url: 'https://play.google.com/store/apps/details?id=com.kkultrip',
      img: ic_android,
    },
    {
      name: 'App Store',
      url: 'itms-apps://itunes.apple.com/app/apple-store/id6443998458?mt=8',
      img: ic_apple,
    },
  ],
  social: [
    {
      name: 'Youtube',
      url: 'https://youtube.com/playlist?list=PLigwiU6vbLHtgQkNTYIDhOZpZD7cm8ZP5',
      img: ic_youtube_circle,
    },
    { name: 'kakao', url: 'https://pf.kakao.com/_alIxdb', img: ic_kakao },
    { name: 'instagram', url: 'https://www.instagram.com/kkultrip/?hl=ko', img: ic_insta },
    { name: 'blog', url: 'https://blog.naver.com/kkultrip', img: ic_blog },
  ],
};

export const FootMissionInfo: IconList = {
  icon: [
    {
      name: '텀블러',
      img: ic_tumbler,
    },
    {
      name: '비행기',
      img: ic_airport,
    },
    {
      name: '걷기',
      img: ic_walking,
    },
    {
      name: '배',
      img: ic_ship,
    },
  ],
};
