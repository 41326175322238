import styled from 'styled-components';
import { ReactNode } from 'react';
import { GRID_THEME } from '@common/theme';

interface STGridProps {
  sx: any;
  mobsx: any;
}
const STGrid = styled.div<STGridProps>((props) => {
  return {
    ...GRID_THEME,
    ...props.sx,
    [`@media only screen and (${props.theme.sm})`]: {
      gap: '13px',
      ...props.mobsx,
    },
  };
});

interface GridProps extends React.HTMLAttributes<HTMLDivElement> {
  sx?: any;
  mobsx?: any;
  children?: ReactNode;
}

export const Grid: React.FC<GridProps> = (props): JSX.Element => {
  const { sx = {}, mobsx = {}, className, children, ...other } = props;

  return (
    <STGrid sx={sx} mobsx={mobsx} className={[className || ''].join(' ')} {...other}>
      {children}
    </STGrid>
  );
};

// export default Grid;
