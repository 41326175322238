import styled from 'styled-components';
import { ReactNode } from 'react';
// import { BG_COLORS } from '@common/theme';
// import { BG_COLOR } from '@common/type';

interface STModalProps {
  sx: any;
  mobsx: any;
  height: string;
  width: string;
  direction: string;
  // bgcolor: BG_COLOR;
}
const STModal = styled.section<STModalProps>((props) => {
  return {
    // backgroundColor: BG_COLORS[props.bgcolor],
    // display: 'flex',
    // width: '100vw',
    // flexDirection: props.direction,
    // justifyContent: 'center',
    // height: props.height,
    ...props.sx,
    [`@media only screen and (${props.theme.lg})`]: {
      // flexDirection: 'column',
      // height: props.mobHeight,
      // padding: '32px 16px 32px 16px',
      // ...props.mobsx,
    },
  };
});

interface ModalProps extends React.HTMLAttributes<HTMLDivElement> {
  sx?: any;
  mobsx?: any;
  width?: string;
  children?: ReactNode;
  height?: string;
  direction?: string;
  as?: string;
}

export const Modal: React.FC<ModalProps> = (props): JSX.Element => {
  const {
    as = '',
    sx = {},
    mobsx = {},
    width = '100vw',
    height = '100vh',
    direction = 'row',
    className,
    onClick,
    children,
    ...other
  } = props;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <STModal
      as={as}
      sx={sx}
      width={width}
      mobsx={mobsx}
      height={height}
      onClick={handleClick}
      direction={direction}
      className={[className || 'modal'].join(' ')}
      {...other}>
      {children}
    </STModal>
  );
};

export default Modal;
