import styled from 'styled-components';
import { Text, Box } from '@components/atomic';
import { Section, Wrap, Grid } from '@components/molecular';

import { IconInfo } from '@common/constants/ConstantInfo';

import img_main from '@assets/images/img_fourth.png';

const IconBox = styled.div`
  display: 'flex';
  justify-content: center;
  align-items: center;
`;

const FourthPage = () => {
  return (
    <Section direction="column" mobheight="auto" mobsx={{ padding: '100px 32px 100px 32px' }}>
      <Section
        as={'div'}
        height="auto"
        mobsx={{ justifyContent: 'flex-end', width: 'auto' }}
        mobheight="auto">
        <Wrap sx={{ flex: '1' }} align={'flex-end'} mobsx={{ display: 'none' }}>
          <img src={img_main} alt="꿀트립 네번째 이미지" className="img-fourth" />
        </Wrap>
        <Wrap
          sx={{ flex: '1', marginLeft: '140px' }}
          mobsx={{
            margin: '0px',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <div>
            <Text
              size={'xl'}
              as={'h3'}
              sx={{ marginBottom: '16px' }}
              mobsx={{ marginBottom: '4px' }}>
              꿀트립 제휴점에서 <br />
              할인 혜택을 제공해드립니다!
            </Text>
            <Text size={'md'} className="font-pre">
              벌집에 현지인이 인증한 제휴점에 할인 혜택과 정보를 제공해드립니다!
            </Text>
          </div>
          <Wrap
            sx={{ display: 'none' }}
            align={'flex-end'}
            mobsx={{ display: 'block', marginTop: '20px' }}>
            <img src={img_main} alt="꿀트립 네번째 이미지" className="img-fourth" />
          </Wrap>
          <Grid sx={{ marginTop: '70px' }} mobsx={{ marginTop: '20px' }}>
            {IconInfo.icon.map((el, idx: number) => {
              return (
                <IconBox key={idx}>
                  {idx < 2 ? (
                    <Box size={'sm'} bgcolor={'lightYellow'}>
                      <img src={el.img} alt={`꿀트립 ${el.name}`} />
                    </Box>
                  ) : (
                    <Box size={'sm'} bgcolor={'grey'}>
                      <img src={el.img} alt={`꿀트립 ${el.name}`} />
                    </Box>
                  )}
                  <Text size={'xs'} sx={{ textAlign: 'center' }} font={'noto'}>
                    {el.name}
                  </Text>
                </IconBox>
              );
            })}
          </Grid>
        </Wrap>
      </Section>
      <Section
        as={'div'}
        height={'auto'}
        sx={{ paddingTop: '28px' }}
        mobsx={{ paddingTop: '16px', alignItems: 'center', width: 'auto' }}
        mobheight="auto">
        <Text as={'p'} size={'xs'} color={'secondary'} className="font-s">
          *현재 식당, 카페 유형의 제휴점만 할인 혜택을 제공하고 있으며, 액티비티, 티켓, 모빌리티,
          숙박, 쇼핑, 기타 유형은 순차적으로 진행할 예정입니다.
        </Text>
      </Section>
    </Section>
  );
};

export default FourthPage;
