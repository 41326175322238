import { Text } from '@components/atomic';

import img_main from '@assets/images/img_fifth.png';

const FifthPage = () => {
  return (
    <div className="flex flex-col items-start justify-start 2xl:flex-row bg-[#FCF2BF] pt-[80px]  2xl:pt-[160px] 2xl:justify-center px-4">
      <div className="mb-[40px] 2xl:pt-[100px]">
        <Text size={'xl'} as={'h3'} sx={{ marginBottom: '16px' }} mobsx={{ marginBottom: '4px' }}>
          나의 꿀을 확인하세요!
        </Text>
        <Text size={'md'} className="font-pre" mobsx={{ marginBottom: '4px', textAlign: 'center' }}>
          여행을 다니면서 받았던 혜택을 확인할 수 있어요
        </Text>
      </div>
      <div>
        <img src={img_main} alt="꿀트립 다섯번째 이미지" className="bg-kkul" />
      </div>
    </div>
  );
};

export default FifthPage;
