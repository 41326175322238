import footIcon from '@assets/images/img_career.svg';

export const FootSecondPage = () => {
  return (
    <section className="w-full bg-[#363F34] py-[48px] xl:py-[120px] px-[24px]">
      <div className="w-full h-full flex flex-col items-center">
        <div className="mb-[48px] w-[153px] xl:w-[375px] xl:w-auto">
          <img src={footIcon} alt="탄소발자국 아이콘" />{' '}
        </div>
        <h3 className="font-sd text-[#fff] font-[500] text-[20px] xl:text-[30px] text-center leading-[125%]">
          여행을 다니면서 나의 탄소발자국을 <br className="block xl:hidden" /> 쉽게 기록할수 있는
          방법이 없을까요?
        </h3>
      </div>
    </section>
  );
};
