import React, { useState } from 'react';

import { isMobile } from 'react-device-detect';

import { LinkInfo } from '@common/constants/ConstantInfo';

import { ModalApplePage, ModalGooglePage } from '@components/template';

interface NavBarProps {}

const NavBar: React.FC<NavBarProps> = () => {
  // 모달 상태를 관리하는 state
  const [isModalAppleOpen, SetIsModalAppleOpen] = useState(false);
  const [isModalGoogleOpen, SetIsModalGoogleOpen] = useState(false);

  // 웹 모바일 구분에 따른 이벤트 관리
  const handleModal = (el: string) => {
    if (isMobile) {
      if (el === 'Google Play') {
        return (window.location.href =
          'https://play.google.com/store/apps/details?id=com.kkultrip');
      } else {
        return (window.location.href =
          'itms-apps://itunes.apple.com/app/apple-store/id6443998458?mt=8');
      }
    } else {
      if (el === 'Google Play') {
        SetIsModalGoogleOpen(!isModalGoogleOpen);
        return;
      } else {
        SetIsModalAppleOpen(!isModalAppleOpen);
        return;
      }
    }
  };

  return (
    <>
      <div className="flex flex-row w-full xl:w-[300px] gap-4 ">
        {LinkInfo.appLink.map((el, idx: number) => {
          return (
            <button
              key={idx}
              className="flex items-center justify-center gap-4 w-full font-sd  z-[800]"
              onClick={() => handleModal(el.name)}>
              <img src={el.img} alt={`꿀트립 ${el.name}}`} />
              {el.name}
            </button>
          );
        })}
      </div>

      {isModalAppleOpen && (
        <ModalApplePage onClose={() => SetIsModalAppleOpen(!isModalAppleOpen)} />
      )}
      {isModalGoogleOpen && (
        <ModalGooglePage onClose={() => SetIsModalGoogleOpen(!isModalGoogleOpen)} />
      )}
    </>
  );
};
export default NavBar;
