import { Text } from '@components/atomic';
import { Section, Wrap } from '@components/molecular';

import Img_third from '@assets/images/img_third.png';
import Img_third_mob from '@assets/images/img_third_mob.png';
import IconTrip from '@assets/images/ic_trip_yellow.svg';

const ThirdPage = () => {
  return (
    <Section mobheight="auto" mobsx={{ padding: '100px 0 100px 0' }} id="kkultrip">
      <Wrap
        sx={{ flex: '1', paddingTop: '200px' }}
        align={'flex-end '}
        justify={'start'}
        mobsx={{ flex: '0.5', alignItems: 'center', justifyContent: 'flex-end', padding: '32px' }}>
        <div className="flex flex-col items-center xl:items-start text-center ">
          <div className="flex gap-2 items-center bg-[#FFFADF] py-4 px-8 rounded-full mb-[56px] border border-[#131313] border-opacity-10">
            <img src={IconTrip} alt="트립 아이콘" />
            <p className="font-sd font-[500] text-[16px] xl:text-[18px] text-[#444]">
              트렌디한 여행할인 서비스
            </p>
          </div>
          <Text
            size={'xl'}
            as={'h3'}
            sx={{ marginBottom: '16px' }}
            mobsx={{ marginBottom: '4px', textAlign: 'center' }}>
            여행의 감동! 현금의 감동!
          </Text>
          <Text
            size={'md'}
            className="font-pre"
            mobsx={{ marginBottom: '4px', textAlign: 'center' }}>
            여행 전에 일일이 할일•쿠폰을 찾을 필요없이 여행 동안
            <br className="br-mob" /> 결제하신 영수증을 등록해주세요.
          </Text>
        </div>
      </Wrap>
      <Wrap
        sx={{ flex: '1.3' }}
        mobsx={{
          flex: '1',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginTop: '20px',
        }}>
        <img src={Img_third} alt="꿀트립 세번째 이미지" className="img-third" />
        <img src={Img_third_mob} alt="꿀트립 세번째 이미지" className="img-third-mob" />
      </Wrap>
    </Section>
  );
};

export default ThirdPage;
