import { Text } from '@components/atomic';

import { FootMissionInfo } from '@common/constants/ConstantInfo';

import bgImg from '@assets/images/img_mockup_footprint.png';

export const FootMissionPage = () => {
  return (
    <section
      className="w-full h-auto 2xl:h-dvh flex flex-col 2xl:flex-row items-center justify-center gap-8 px-6 pt-[80px] 2xl:pt-0 relative overflow-x-hidden"
      style={{ overflowX: 'hidden' }}>
      <div className="xl:flex-1 w-full">
        <div className="flex flex-col items-start 2xl:items-end w-full h-full ">
          <div className="flex gap-6 mb-8 pr-16 overflow-x-hidden">
            {FootMissionInfo.icon.map((el, idx) => {
              return <img className="w-[56px] xl:w-auto" src={el.img} alt={el.name} key={idx} />;
            })}
          </div>
          <Text size={'xl'} as={'h3'} sx={{ marginBottom: '16px' }} mobsx={{ marginBottom: '4px' }}>
            미션과 함께하는 즐거운 여행,
            <br />
            뱃지를 모아보세요
          </Text>
        </div>
      </div>
      <div className="hidden 2xl:flex flex-[1.3] w-full h-full bg-footMission"></div>
      <div className="overflow-x-hidden">
        <img
          className="block 2xl:hidden min-w-[800px] translate-x-1/4"
          src={bgImg}
          alt="배경 이미지"
        />
      </div>
    </section>
  );
};
